<template>
  <div
    class="modal modal__right"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    v-if="show"
  >
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a class="modal__close modal__close--black" @click="$emit('close')">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">Close Assigned Task</h5>
        </div>
        <div class="modal__body">
          <form>
            <div class="form__item">
              <div class="form__item">
                <label
                  class="form__label text--regular-alt text--bold text--black"
                  >notes</label
                >
                <textarea
                  cols="5"
                  rows="5"
                  class="input form__input"
                  placeholder=""
                  v-model="tasks.note"
                ></textarea>
              </div>
            </div>

            <div class="form__item">
              <div class="form__action">
                <button
                  class="button form__button form__button--lg w-30 save-changes"
                  v-if="loading"
                >
                  <div class="spinner-border texxt-light" role="status"></div>
                </button>
                <button
                  data-dismiss="#maxWithdrawalModal"
                  type="button"
                  class="button form__button form__button--lg w-30 save-changes"
                  @click.prevent="closeCurrentTask"
                  v-else
                >
                  Save Changes
                </button>
                <a
                  role="button"
                  data-dismiss="#maxWithdrawalModal"
                  class="
                    modal__close
                    form__action__text form__action__text--danger
                    text--capital
                    ml6
                  "
                  @click="$emit('close')"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
export default {
  name: "closeTask",
  props: {
    show: Boolean,
    close: Function,
    task: Object,
  },
  data() {
    return {
      tasks: {
        note: "",
      },
      loading: false,
    };
  },
  computed: {
    gettaskKey() {
      return this.task.taskKey;
    },
    gettaskComment() {
      return this.task.taskDescription;
    },
  },
  methods: {
    closeCurrentTask() {
      this.loading = true;
      ApiService.post(
        "TaskManagement/closetask",
        {
          taskKey: this.gettaskKey,
          comment: this.tasks.note,
          closedUserKey: this.task.assignedToKey,
        }
      )
        .then((response) => {
          if (response.data.status !== false) {
            this.loading = false;
            this.$emit("task-closed-successfully", response.data.message);
            this.$emit("close");
          } else {
            this.loading = false;
            this.$emit("error-closing-task", response.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("error-closing-task", error.response.data.message);
        });
    },
  },
};
</script>
